const config = {
  gateway: {
    showOnlyForHardwareIds: [
      // hardwareIds that can see the Gateway components
      // '01e43e6c' // "Poppy"
    ]
  },
  dfs: {
    showDFS: false
  },
  savings: {
    carbonIntensity: 393.86,
    showOnlyForHardwareIds: [
      // hardwareIds that can see the Savings component
      '005b5f57',
      '005b87cf',
      '005bdf21',
      '00688152',
      '0068d4f3',
      '0068faa8',
      '00691659',
      '006cfb69',
      '008a0a8c',
      '012c53ee',
      '0162e4ee',
      '01c83815',
      '01e43dc1',
      '01e5eb65',
      '01e5ebc5',
      'dd04a197',
      'dd66c5a4',
      'dd66f5f5',
      'dd6b3a53',
      '01e43f1e'
    ]
  },
  clusters: {
    allowSingularViewCustomerAccountIds: [
      // customerAccountIds that can view clustered units singularly:
      // 997 // j@omega934.com
      744, // Richard Newman
      877 // Ivan Justo
    ]
  }
}

export default config
