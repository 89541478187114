import React, { useState } from 'react'
import { Modal, Button, Menu, Grid } from 'semantic-ui-react'
import { Link, useNavigate, Outlet } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'

import './Preferences.css'

function Preferences() {
  const navigate = useNavigate()

  return (
    <>
      <Modal
        id="Preferences"
        size="large"
        open
        centered={false}
        dimmer="blurring"
        closeOnDimmerClick={false}
        closeOnDocumentClick={false}
        closeOnEscape={false}
        onClose={(e) => {
          e.stopPropagation()
          navigate('/home')
        }}
      >
        <ToastContainer
          containerId="toastPreferences"
          position="top-center"
          autoClose={1000}
          hideProgressBar
          newestOnTop={true}
          // closeOnClick
          rtl={false}
          pauseOnFocusLoss={false}
          closeButton={false}
          // draggable
          // pauseOnHover
          theme="dark"
          limit={1}
        />
        <Outlet />
      </Modal>
    </>
  )
}

export { Preferences }
