import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { Header, Icon, Segment, Button, Grid, Divider, Label, Message } from 'semantic-ui-react'
import { accountOrUnitHasPurchasedProduct } from '../MainMenu/Subscriptions'
import { config, debugMessage, parseModelCode, getPaymentGatewayUrl } from '../../common'
import { authenticator } from '../../authenticator'
import { ajax } from '../../ajax'
import { getEnglishText } from '../../dictionary'

import { ScheduleEditor } from '../Schedule/ScheduleEditor'
import { EPSScheduleEditor } from '../EPSSchedule/EPSScheduleEditor'
import { SmartSchedule } from '../SmartSchedule/SmartSchedule'
import { BetaProgramme } from '../BetaProgramme/BetaProgramme'
import { DFS } from '../DFS/DFS'
import { LEDSettings } from '../LEDSettings/LEDSettings'

import './Settings.css'

function Settings(props) {
  const { viewerDevice, user, unitId, onSaveSchedule, unitDetails, isStaff, units, clusters, device } = props
  const idToken = authenticator.getToken()

  const customerAccountId = units[0]?.accountId ? units[0].accountId : null

  const [scheduleEditorOpen, setScheduleEditorOpen] = useState(false)
  const [smartScheduleOpen, setSmartScheduleOpen] = useState(false)
  const [dfsOpen, setDFSOpen] = useState(false)
  const [epsScheduleOpen, setEpsScheduleOpen] = useState(false)
  const [ledSettingsOpen, setLEDSettingsOpen] = useState(false)

  const [scheduleData, setScheduleData] = useState(null)
  const [settingsData, setSettingsData] = useState(null)

  const [errorMessage, setErrorMessage] = useState(null)

  const [displayAsCluster, setDisplayAsCluster] = useState(false)
  const [isMemberOfCluster, setIsMemberOfCluster] = useState(false)

  const [showSmartStor, setShowSmartStor] = useState(false)

  // initial fetch
  useEffect(() => {
    debugMessage('Mounted', 'Settings')
    fetchData()
    return () => {}
  }, [])

  // show / hide features
  useEffect(() => {
    determineSmartStorAvailability()
  }, [user, unitDetails, isMemberOfCluster])

  // fetch on props change
  useEffect(() => {
    fetchData()
    return () => {}
  }, [props, units, scheduleEditorOpen, smartScheduleOpen, epsScheduleOpen, dfsOpen])

  useEffect(() => {
    if (!clusters[0] || !clusters[0][0]) return
    setDisplayAsCluster(clusters[0][0].clusterHash)
    setIsMemberOfCluster(clusters[0][0].clusterUnits !== null)
  }, [clusters])

  function launchUrl(url) {
    window.open(url, '_blank')
  }

  function CustomBox({ children }) {
    return (
      <div className="custom-box">
        <>{children}</>
      </div>
    )
  }

  function CustomLabel({ data }) {
    return (
      <Label as="span" size={device.includes('mobile') ? 'tiny' : 'small'} circular className={`status ${data.class}`}>
        {data.text.toUpperCase()}
      </Label>
    )
  }

  async function determineSmartStorAvailability() {
    if (units.length !== 1 && !isMemberOfCluster) return setShowSmartStor(false)
    if (!unitDetails.smartScheduleEligible) return setShowSmartStor(false)

    const parsedModel = parseModelCode(unitDetails.model)
    const { giftSmartStor } = parsedModel

    ////////////////////////////////////////////////////////////////
    // TEMPORARILY BYPASS SUBSCRIPTION REQUIREMENTS FOR SMARTSTOR //
    ////////////////////////////////////////////////////////////////
    return setShowSmartStor(true)
    ////////////////////////////////////////////////////////////////

    if (giftSmartStor) return setShowSmartStor(true) // model predates P5

    // account must have active subscription
    const purchasedPowervaultPlus = await accountOrUnitHasPurchasedProduct(customerAccountId, 'powervault-plus-1-month-reoccurring-subscription')
    if (purchasedPowervaultPlus) return setShowSmartStor(true)

    return setShowSmartStor('purchase-required')
  }

  function fetchData() {
    ajax
      .fetchUnitSchedules(unitId, idToken)
      .then((scheduleData) => setScheduleData(scheduleData))
      .catch((err) => setErrorMessage(err))

    ajax
      .fetchSettings(unitId, idToken)
      .then((settingsData) => setSettingsData(settingsData))
      .catch((err) => setErrorMessage(err))
  }

  function getScheduleStatusProps() {
    try {
      if (
        scheduleData.schedule.monday.length === 0 &&
        scheduleData.schedule.tuesday.length === 0 &&
        scheduleData.schedule.wednesday.length === 0 &&
        scheduleData.schedule.thursday.length === 0 &&
        scheduleData.schedule.friday.length === 0 &&
        scheduleData.schedule.saturday.length === 0 &&
        scheduleData.schedule.sunday.length === 0
      )
        return { class: 'disabled', text: 'Disabled' }
      else {
        if (settingsData.contract.smartScheduleEnabled === 1)
          return { class: 'overridden pv-blue-background-color light-text', text: 'Overridden by SMARTSTOR' }
        return { class: 'enabled pv-green-background-color light-text', text: 'Enabled' }
      }
    } catch (e) {
      return { class: 'disabled', text: 'Disabled' }
    }
  }

  function getSmartScheduleStatusProps() {
    try {
      if (settingsData.contract.smartScheduleEnabled === 1 && settingsData.contract.dfsOptIn === 1 && config.dfs.showDFS) {
        return { class: 'overridden pv-blue-background-color light-text', text: 'Can be overridden by DFS' }
      } else if (settingsData.contract.smartScheduleEnabled === 1) {
        return { class: 'enabled pv-green-background-color light-text', text: 'Enabled' }
      } else {
        return { class: 'disabled', text: 'Disabled' }
      }
    } catch (e) {
      return { class: 'disabled', text: 'Disabled' }
    }
  }

  function getDFSStatusProps() {
    try {
      if (settingsData.contract.dfsOptIn === 1) return { class: 'enabled pv-green-background-color light-text', text: 'Enabled' }
      else return { class: 'disabled', text: 'Disabled' }
    } catch (e) {
      return { class: 'disabled', text: 'Disabled' }
    }
  }

  function getEpsScheduleStatusProps() {
    try {
      if (
        scheduleData.epsSchedule.monday.length === 0 &&
        scheduleData.epsSchedule.tuesday.length === 0 &&
        scheduleData.epsSchedule.wednesday.length === 0 &&
        scheduleData.epsSchedule.thursday.length === 0 &&
        scheduleData.epsSchedule.friday.length === 0 &&
        scheduleData.epsSchedule.saturday.length === 0 &&
        scheduleData.epsSchedule.sunday.length === 0
      )
        return { class: 'disabled', text: 'Disabled' }
      else return { class: 'enabled pv-green-background-color light-text', text: 'Enabled' }
    } catch (e) {
      return { class: 'disabled', text: 'Disabled' }
    }
  }

  function launchSmartHome() {
    ajax
      .fetchRaw(idToken, `smartHome/getLaunchUrl?hardwareId=${unitId}`)
      .then((data) => {
        if (data.launchUrl) window.open(data.launchUrl)
        else setErrorMessage(data.errorMessage)
      })
      .catch((error) => {
        setErrorMessage(error)
      })
  }

  return (
    <Segment id="settings" style={{ flexGrow: 'inherit' }}>
      {errorMessage && <Message error>{errorMessage}</Message>}

      {/* Header */}
      <Header as="h2">
        <Icon name="settings" style={{ opacity: 0.6, paddingRight: '0.2em' }} />
        <Header.Content>
          {getEnglishText('settings : title')}&nbsp;&nbsp;
          {units.length > 1 && (
            <span className="clusters-desc" style={{ fontSize: clusters.length > 1 ? '75%' : '100%' }}>
              <>
                ({units.length} units
                {clusters.length > 1 && <>{`, in ${clusters.length} clusters`}</>})&nbsp;&nbsp;
              </>
            </span>
          )}
          <Header.Subheader>{getEnglishText('settings : description')}</Header.Subheader>
        </Header.Content>
      </Header>

      <Divider hidden />
      <Divider hidden />

      {/* Grid of features */}
      <Grid columns={viewerDevice === 'desktop-large' ? 3 : 2} stackable padded relaxed>
        {/* Manual Schedule */}
        <Grid.Column>
          <CustomBox>
            <p>
              <strong>{getEnglishText('settings : manunal-schedule')}</strong>
              <CustomLabel data={getScheduleStatusProps()} />
            </p>
            <p>{getEnglishText('settings : manunal-schedule-description')}</p>
            <Button fluid primary onClick={() => setScheduleEditorOpen(true)}>
              {displayAsCluster ? <>{getEnglishText('settings : manunal-schedule-cluster-edit')}</> : <>{getEnglishText('settings : manunal-schedule-edit')}</>}
            </Button>
          </CustomBox>
        </Grid.Column>

        {/* SMARTSTOR */}
        {showSmartStor === true && (
          <Grid.Column>
            <CustomBox>
              <p>
                <strong>{displayAsCluster ? getEnglishText('settings : smartstor') : getEnglishText('settings : smartstor')}</strong>
                <CustomLabel data={getSmartScheduleStatusProps()} />
              </p>
              <p>{getEnglishText('settings : smartstor-description')}</p>
              <Button secondary fluid onClick={() => setSmartScheduleOpen(true)}>
                {getSmartScheduleStatusProps().text === 'Disabled'
                  ? displayAsCluster
                    ? getEnglishText('settings : smartstor-set-up-cluster')
                    : getEnglishText('settings : smartstor-set-up')
                  : getEnglishText('settings : smartstor-settings')}
              </Button>
            </CustomBox>
          </Grid.Column>
        )}

        {/* SMARTSTOR, subscription required */}
        {showSmartStor === 'purchase-required' && (
          <Grid.Column>
            <CustomBox>
              <p>
                <strong>{getEnglishText('settings : smartstor')}</strong>
                <CustomLabel data={{ text: 'Subscription Required' }} />
              </p>
              <p>{getEnglishText('settings : smartstor-description')}</p>
              <Button
                primary
                fluid
                as={Link}
                to={`https://${getPaymentGatewayUrl()}/?productId=powervault-plus-1-month-reoccurring-subscription&accountId=${customerAccountId}&configId=${
                  units[0].configId
                }&hash=_`}
              >
                {getEnglishText('settings : smartstor-subscribe-to-powervault-plus')}
              </Button>
            </CustomBox>
          </Grid.Column>
        )}

        {/* DFS */}
        {config.dfs.showDFS && (
          <>
            {showSmartStor === true && !isMemberOfCluster && (
              <Grid.Column>
                <CustomBox>
                  <p>
                    <strong>{getEnglishText('settings : dfs')}</strong>
                    <CustomLabel data={getDFSStatusProps()} />
                  </p>
                  <Button secondary fluid onClick={() => setDFSOpen(true)}>
                    {getEnglishText('settings : dfs-set-up')}
                  </Button>
                </CustomBox>
              </Grid.Column>
            )}
          </>
        )}

        {/* EPS */}
        {clusters.length === 1 && unitDetails.model && parseModelCode(unitDetails.model).epsAbility && (
          <Grid.Column>
            <CustomBox>
              <p>
                <strong>{getEnglishText('settings : eps-schedule')}</strong>
                <CustomLabel data={getEpsScheduleStatusProps()} />
              </p>
              <p>{getEnglishText('settings : eps-schedule-description')}</p>
              <Button secondary fluid color="orange" onClick={() => setEpsScheduleOpen(true)}>
                {getEnglishText('settings : eps-schedule-edit')}
                {!parseModelCode(unitDetails.model).epsAbility && isStaff ? <>&nbsp;&nbsp;(Staff only)</> : null}
              </Button>
            </CustomBox>
          </Grid.Column>
        )}

        {/* GATEWAY SETTINGS */}
        {config.gateway.showOnlyForHardwareIds.includes(unitDetails.hardwareId) && (
          <>
            {clusters.length === 1 && units.length === 1 && (
              <Grid.Column>
                <CustomBox>
                  <p>
                    <strong>Gateway</strong>
                  </p>
                  <p>Powervault Gateway Settings</p>

                  <Button secondary fluid as={Link} to="/home/preferences/gateway-settings">
                    Gateway Settings
                  </Button>
                </CustomBox>
              </Grid.Column>
            )}
          </>
        )}

        {/* LED Settings */}
        {units.length === 1 && unitDetails.model && parseModelCode(unitDetails.model).hasLedSettings && (
          <Grid.Column>
            <CustomBox>
              <p>
                <strong>{getEnglishText('settings : led-settings')}</strong>
              </p>
              <p>{getEnglishText('settings : led-settings-description')}</p>
              <Button secondary fluid onClick={() => setLEDSettingsOpen(true)}>
                {getEnglishText('settings : led-settings-edit')}
              </Button>
            </CustomBox>
          </Grid.Column>
        )}

        {/* Powervault Smart Home */}
        {/* {units.length === 1 && isStaff && (
          <Grid.Column>
            <CustomBox>
              <p>
                <strong>Powervault Smart Home</strong>
              </p>
              <p>Allow your Powervault to control smart devices in your home in response to available surplus power.</p>
              <Button secondary fluid onClick={() => launchSmartHome()}>
                Powervault Smart Home
              </Button>
            </CustomBox>
          </Grid.Column>
        )} */}
      </Grid>

      {/* Beta programme */}
      {units.length === 1 && <BetaProgramme unit={units[0]} />}

      {scheduleEditorOpen && (
        <ScheduleEditor
          // hardwareId={unitId}
          device={device}
          unit={units[0]}
          unitDetails={unitDetails}
          onSaveSchedule={onSaveSchedule}
          clusters={clusters}
          onClose={() => setScheduleEditorOpen(false)}
          isStaff={isStaff}
        />
      )}

      {smartScheduleOpen && (
        <SmartSchedule
          displayAsCluster={displayAsCluster}
          units={units}
          hardwareId={unitId}
          onClose={() => setSmartScheduleOpen(false)}
          onSaveSchedule={onSaveSchedule}
        />
      )}

      {dfsOpen && <DFS hardwareId={unitId} onClose={() => setDFSOpen(false)} />}

      {epsScheduleOpen && (
        <EPSScheduleEditor device={device} unitDetails={unitDetails} hardwareId={unitId} clusters={clusters} onClose={() => setEpsScheduleOpen(false)} />
      )}

      {ledSettingsOpen && <LEDSettings unitDetails={unitDetails} hardwareId={unitId} onClose={() => setLEDSettingsOpen(false)} />}
    </Segment>
  )
}

export { Settings }
